const productCategoryModel = {
    name: '',
    image: '',
    imageThumb:'',
    status: null
}

const productModel = {
    name: '',
    imageCover:'',
    imageCoverName:'',
    description: '',
    categoryUuid: '',
    recommendation: 1,
    status: 1,
    details:[]
}

export {
    productCategoryModel,
    productModel
}