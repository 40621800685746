const summaryDataModel = {
  order: 0,
  orderAmount: 0,
  orderCutting: 0,
  orderPrinting: 0,
  orderPacking: 0,
  orderShipping: 0,
  orderDelivered: 0,
  user: 0,
};

const summaryChartModel = {
  order: [],
  orderAmount: [],
  user: null,
};

const summaryListModel = {
  productBestSeller: [],
};

export { summaryDataModel, summaryChartModel, summaryListModel };
