const bannerModel = {
    title:'',
    description:'',
    image:'',
    imageThumb: '',
    startAt:'',
    endAt:'',
    actionUrl:'',
    status: null
}

export {
    bannerModel
}